import React from 'react';
import classNames from 'classnames';
import Popover from '../Components/Popover';
import TimeTrackingEntryLogModal from './TimeTrackingEntryLogModal';
import TextButton from '../Components/TextButton';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/pro-regular-svg-icons';
import { BaseTimeTrackingEntryFragment, SupplementType, TimeTrackingStatus } from '../GraphQL/indexV2';
import { useDeleteTimeTrackingDayEntry } from './TimeRegHelpers';
import { faBell, faBellPlus, faUtensils } from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import { FeatureFlagEnums } from '../FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';

interface SchedulerEventProps {
	dayId: string;
	entry: BaseTimeTrackingEntryFragment
	readOnlyMode?: boolean;
	standAlone?: boolean;
	setShowEditTimereg: (value: boolean) => void;
	setActiveTimeEntry: (value: BaseTimeTrackingEntryFragment) => void;
	compactMode?: boolean;
}

const SchedulerEvent: React.FC<SchedulerEventProps> = ({ dayId, entry, readOnlyMode = false, standAlone = false, setShowEditTimereg, setActiveTimeEntry, compactMode = false }) => {
	const { t } = useTranslation();

	const timeregExtendedLogging = useFlag(FeatureFlagEnums.TIMEREG_EXTENDED_LOGGING);

	const deleteInvoicedTimeRegs = useFlag(FeatureFlagEnums.DELETE_INVOICED_TIMEREGS);
	const [entryId, setEntryId] = React.useState<string | undefined>(undefined);
	const { deleteTimeTrackingDayEntry, deleteTimeTrackingDayEntryLoading } = useDeleteTimeTrackingDayEntry();

	const calculateHeightForDuration = (durationInHours: number, includesLunch: boolean): number => {
		let totalDurationInHours = durationInHours;
		if (includesLunch) {
			totalDurationInHours += 0.5;
		}
		const durationInMinutes = totalDurationInHours * 60;
		return (durationInMinutes / 15) * 48;
	};

	if (compactMode) {
		return (
			<div
				className={classNames('entry t-0 z-10 w-full pl-2 text-sm border-l-4 border-b-gray-400 border-b-1 overflow-hidden', {
					'absolute': !compactMode,
					'bg-cyan-calm border-green-dark': entry.jobNo === 'INTERN' && entry.timeTrackingStatus !== TimeTrackingStatus.Rejected,
					'bg-blue-lightcalm border-blue-calm': entry.jobNo !== 'INTERN' && entry.timeTrackingStatus !== TimeTrackingStatus.Rejected,
					'bg-cyan-calm border-green-dark diagonal-bg-green cursor-not-allowed': entry.jobNo === 'INTERN' && entry.timeTrackingStatus === TimeTrackingStatus.Approved,
					'bg-blue-lightcalm border-blue-calm diagonal-bg-blue cursor-not-allowed': entry.jobNo !== 'INTERN' && entry.timeTrackingStatus === TimeTrackingStatus.Approved,
					'border-red bg-red-calm bg-opacity-25': entry.timeTrackingStatus === 'REJECTED',
				})}
				style={{ height: compactMode ? 'auto' : calculateHeightForDuration(entry?.hours ?? 0, entry.includesLunch) + 'px' }}
			>
				<div className="flex flex-row pt-1 leading-snug">
					<div className="w-2/3">
						<div className="flex">
							<p className="m-0">
								{readOnlyMode && <span className="font-semibold">{entry.startDate} </span>}
								{!standAlone && <span className="font-semibold">{compactMode && `${entry.startTime} - ${entry.endTime}`}</span>}
							</p>
							{entry.lastModifiedAt &&
								<Popover
									placement="bottom"
									content={
										<div className="rounded-b-default border-1 shadow-default border-gray-200 bg-white p-2 text-xs">
											<p>{DateTime.fromISO(entry.lastModifiedAt).toLocaleString(DateTime.DATETIME_SHORT)}</p>
											<p>{entry.lastModifiedBy?.name}</p>
										</div>
									}
								>
									<div className="ml-2">
										{timeregExtendedLogging
											? <TextButton text='common.edited' textClassName='italic' onClick={() => setEntryId(entry.id)} />
											: <p className='italic text-blue'>{t('common.edited')}</p>
										}
									</div>
								</Popover>
							}
						</div>
						<p>
							<span className="font-semibold">{entry?.case?.erpNo ?? 'Intern'}</span>
							<br />
							{entry.case
								? `${entry.case.damage.contact.address.road} ${entry.case.damage.contact.address.houseNumber}, ${entry.case.damage.contact.address.postalCode} ${entry.case.damage.contact.address.city}`
								: ''
							}
						</p>
					</div>
					<div className="w-1/3 justify-end">
						{(entry.bcStatus !== TimeTrackingStatus.Approved) &&
							<div className="flex flex-row justify-end items-end text-xs space-x-4 px-2">
								<FontAwesomeIcon
									icon={faEdit}
									style={{ width: '20px', height: '18px' }}
									className="cursor-pointer"
									onClick={() => {
										setActiveTimeEntry(entry);
										setShowEditTimereg(true);
									}}
								/>
								<FontAwesomeIcon
									icon={faTrashAlt}
									style={{ width: '16px', height: '18px' }}
									className="cursor-pointer"
									onClick={async () => {
										if (!deleteTimeTrackingDayEntryLoading) {
											await deleteTimeTrackingDayEntry(dayId, entry.id);
										}
									}}
								/>
							</div>
						}
						{(entry.bcStatus === TimeTrackingStatus.Approved && deleteInvoicedTimeRegs) &&
							<div className="flex flex-row justify-end items-end text-xs space-x-4 px-2">
								<FontAwesomeIcon
									icon={faTrashAlt}
									style={{ width: '16px', height: '18px' }}
									className="cursor-pointer"
									onClick={async () => {
										if (!deleteTimeTrackingDayEntryLoading) {
											await deleteTimeTrackingDayEntry(dayId, entry.id);
										}
									}}
								/>
							</div>
						}
						{entry.bcStatus === TimeTrackingStatus.Approved &&
							<div className="flex flex-row justify-end items-end text-xs px-2">
								<p className="opacity-50 italic">{t('common.creditNotePosted')}</p>
							</div>
						}
					</div>
				</div>

				<div className="flex flex-row py-1 leading-snug">
					<div className="w-1/2">{entry?.jobTaskName ?? '-'}</div>
					<div className="w-1/2 flex justify-end items-baseline px-2">
						{!standAlone && <span>{entry?.hours ?? '-'} {t('common.hours').toLowerCase()}</span>}
						{entry.includesLunch &&
							<FontAwesomeIcon icon={faUtensils} size="sm" className="ml-2" />
						}
						{(entry.onCall && !entry.onCallback) &&
							<FontAwesomeIcon icon={faBell} size="sm" className="ml-2" />
						}
						{(entry.onCall && entry.onCallback) &&
							<FontAwesomeIcon icon={faBellPlus} size="sm" className="ml-2" />
						}
					</div>
				</div>

				<div>{entry?.remark ?? '-'}</div>

                <div>
                    <div className="flex flex-row space-x-2 mb-1">
                    {entry.addonLines.map(supLine => (
                        <div key={supLine.id} className={classNames('text-xs px-1 border-1 border-opacity-50 rounded-default', {
                            'border-black': entry.timeTrackingStatus !== 'REJECTED',
                            'bg-cyan-calm border-green-dark diagonal-bg-green cursor-not-allowed': supLine.jobNo === 'INTERN' && supLine.timeTrackingStatus === 'APPROVED',
                            'bg-blue-lightcalm border-blue-calm diagonal-bg-blue cursor-not-allowed': supLine.jobNo !== 'INTERN' && supLine.timeTrackingStatus === 'APPROVED',
                            'border-red bg-red-calm bg-opacity-25': supLine.timeTrackingStatus === 'REJECTED',
                        })}>
                            {`${supLine.paymentSupplementName} (${supLine.paymentSupplementCode}) ${supLine.supplementType === SupplementType.Piecework ? supLine.pieceworkAmount : supLine.hours} ${supLine.supplementUom?.toLowerCase()}`}
                        </div>
                    ))}
                    </div>
                </div>

				{entryId &&
					<TimeTrackingEntryLogModal entryId={entryId} close={() => setEntryId(undefined)} />
				}

            </div>
        );
    } else {
        return (
            <div
                className={classNames('entry t-0 z-10 w-full pl-2 text-sm border-l-4 border-b-gray-400 border-b-1 overflow-hidden', {
                    'absolute': !compactMode,
                    'bg-cyan-calm border-green-dark': entry.jobNo === 'INTERN' && entry.timeTrackingStatus !== 'REJECTED',
                    'bg-blue-lightcalm border-blue-calm': entry.jobNo !== 'INTERN' && entry.timeTrackingStatus !== 'REJECTED',
                    'bg-cyan-calm border-green-dark diagonal-bg-green cursor-not-allowed': entry.jobNo === 'INTERN' && entry.timeTrackingStatus === 'APPROVED',
                    'bg-blue-lightcalm border-blue-calm diagonal-bg-blue cursor-not-allowed': entry.jobNo !== 'INTERN' && entry.timeTrackingStatus === 'APPROVED',
                    'bg-red bg-opacity-25': entry.timeTrackingStatus === 'REJECTED',
                })}
                style={{ height: compactMode ? '48px' : calculateHeightForDuration(entry?.hours ?? 0, entry.includesLunch) + 'px' }}
            >
                <div className="flex flex-row pt-1 leading-snug">
                    <div className="w-5/12">
                        <p className="m-0">
                            {readOnlyMode && <span className="font-semibold">{entry.startDate} </span>}
                            <span className="font-semibold">{compactMode && `${entry.startTime} - ${entry.endTime}:`} {entry?.case?.erpNo ?? 'Intern'} </span>
                            {entry.case
                                ? `${entry.case.damage.contact.address.road} ${entry.case.damage.contact.address.houseNumber}, ${entry.case.damage.contact.address.postalCode} ${entry.case.damage.contact.address.city}`
                                : ''
                            }
                        </p>
                    </div>
                    <div className="w-2/12">{entry?.jobTaskName ?? '-'}</div>
                    <div className="w-3/12">{entry?.remark ?? '-'}</div>
                    <div className="w-1/12 flex">
                        {entry?.hours ?? '-'} {t('common.hours').toLowerCase()}
                        {entry.includesLunch &&
                            <Popover
                                placement="top"
                                content={
                                    <div className="rounded-b-default border-1 shadow-default border-gray-200 bg-white p-2 text-xs">
                                        <p>{t('timeRegistration.lunchIndicated')}</p>
                                    </div>
                                }
                            >
                                <div>
                                    <FontAwesomeIcon icon={faUtensils} size="sm" className="ml-2" />
                                </div>
                            </Popover>
                        }
                        {entry.onCall &&
                            <Popover
                                placement="top"
                                content={
                                    <div className="rounded-b-default border-1 shadow-default border-gray-200 bg-white p-2 text-xs">
                                        <p>{t('timeRegistration.onCallIndicated')}</p>
                                    </div>
                                }
                            >
                                <div>
                                    <FontAwesomeIcon icon={faBell} size="sm" className="ml-2" />
                                </div>
                            </Popover>
                        }
                    </div>
                    {(!readOnlyMode || entry.timeTrackingStatus !== 'APPROVED') &&
                        <div className="flex justify-end w-1/12 space-x-4 px-2 text-xs">
                            <FontAwesomeIcon
                                icon={faEdit}
                                size="lg"
                                className="cursor-pointer"
                                onClick={() => {
                                    setActiveTimeEntry(entry);
                                    setShowEditTimereg(true);
                                }}
                            />
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                size="lg"
                                className="cursor-pointer"
                                onClick={async () => {
                                if (!deleteTimeTrackingDayEntryLoading) {
                                    await deleteTimeTrackingDayEntry(dayId, entry.id);
                                }
                                }}
                            />
                        </div>
                    }
                </div>
                <div>
                    <div className="flex flex-row space-x-2">
                    {entry.addonLines.map(supLine => (
                        <div key={supLine.id} className="text-xs px-1 border-1 border-black border-opacity-50 rounded-default">
                            {`${supLine.paymentSupplementName} (${supLine.paymentSupplementCode}) ${supLine.hours} ${supLine.supplementUom?.toLowerCase()}`}
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        );
    }
};

export default SchedulerEvent;
