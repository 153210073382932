import React from 'react';
import dateToDateTimeString from '../Helpers/dateToDateTimeString';
import Modal from '@ssg/common/Components/Modal';
import { BaseTimeTrackingEntryFragment, useGetTimeTrackingEntryLogQuery } from '../GraphQL/indexV2';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
interface Props {
	entryId: string;
	close: () => void;
}

const stripProps = (entry: BaseTimeTrackingEntryFragment): Omit<BaseTimeTrackingEntryFragment, 'id' | 'createdAt' | 'user' | 'lastModifiedAt' | 'lastModifiedBy'> => {
	const { id, createdAt, user, lastModifiedAt, lastModifiedBy, ...propsToCheck } = entry;
	return propsToCheck;
};

type DifferingProperties = {
	property: string;
	entry1Value: any;
	entry2Value: any;
}

type ChangeType = {
	id: string;
	changedBy: {
		id: string;
		name: string;
	} | null
	changedAt: string;
	changes: DifferingProperties[];
}

const convertToString = (value: any, t: any): string => {
	if (value === null || value === undefined) {
		return '-';
	}

	if (typeof value === 'boolean') {
		return value ? t('common.yes') : t('common.no');
	}

	if (typeof value === 'string') {
		return value.length === 0 ? '-' : value;
	}
	return value.toString();
};

function compareBaseTimeTrackingEntries(
	entry1: BaseTimeTrackingEntryFragment,
	entry2: BaseTimeTrackingEntryFragment,
	t: any,
): DifferingProperties[] {
	const differingProperties: DifferingProperties[] = [];
	const entry1Stripped = stripProps(entry1);
	const entry2Stripped = stripProps(entry2);

	for (const key in entry1Stripped) {
		if (key === 'addonLines') {
			const lines1 = entry1Stripped['addonLines'].map(l => `${l.paymentSupplementName} (${l.paymentSupplementCode}) ${l.hours} ${l.supplementUom?.toLowerCase()}`).sort((a, b) => a.localeCompare(b));
			const lines2 = entry2Stripped['addonLines'].map(l => `${l.paymentSupplementName} (${l.paymentSupplementCode}) ${l.hours} ${l.supplementUom?.toLowerCase()}`).sort((a, b) => a.localeCompare(b));
			if (lines1.length !== lines2.length) {
				differingProperties.push({
					property: key,
					entry1Value: lines1.length > 0 ? lines1.join(', ') : '-',
					entry2Value: lines2.length > 0 ? lines2.join(', ') : '-',
				});
			} else {
				for (let i = 0; i < Math.max(lines1.length, lines2.length); i++) {
					if (lines1[i] !== lines2[i]) {
						differingProperties.push({
							property: `${key}[${i}]`,
							entry1Value: lines1.length > 0 ? lines1.join(', ') : '-',
							entry2Value: lines2.length > 0 ? lines2.join(', ') : '-',
						});
					}
					break;
				}
			}
			continue;
		}
		if (entry1Stripped.hasOwnProperty(key) && entry1Stripped[key] !== entry2Stripped[key]) {
			if(key === 'case' && entry1Stripped[key] !== null && entry2Stripped[key] !== null) {
				differingProperties.push({
					property: key,
					entry1Value: convertToString(entry1Stripped[key]['erpNo'], t),
					entry2Value: convertToString(entry2Stripped[key]['erpNo'], t),
				});
			} else {
				differingProperties.push({
					property: key,
					entry1Value: convertToString(entry1Stripped[key], t),
					entry2Value: convertToString(entry2Stripped[key], t),
				});
			}
		}
	}

	return differingProperties;
}

const getChanges = (entries: BaseTimeTrackingEntryFragment[], t: any) => {
	const changesArr: ChangeType[] = [];
	for (let i = 0; i < entries.length; i++) {
		const beforeIndex = i - 1;
		if (beforeIndex >= 0) {
			const changes = compareBaseTimeTrackingEntries(entries[beforeIndex], entries[i], t);
			const entry = entries[i];
			changesArr.push({
				changedBy: entry.lastModifiedBy,
				changedAt: entry.createdAt,
				changes,
				id: entry.id,
			});
		}
	}

	return changesArr;
};

const TimeTrackingEntryLogModal: React.FC<Props> = ({ entryId, close }): React.ReactElement => {
	const { t } = useTranslation();
	const [changes, setChanges] = React.useState<ChangeType[]>([]);
	const { data, loading } = useGetTimeTrackingEntryLogQuery({
		variables: {
			id: entryId,
		},
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			setChanges(getChanges(data.timeTrackingEntryLog, t));
		},
	});

	return (
		<Modal
			visible={true}
			close={close}
			title={'timeRegistration.timetrackingentrylog'}
			body={<div className="text-blue space-y-2">
				{loading && <p className="text-blue">
					<FontAwesomeIcon icon={faSpinner} className="animate-spin" /> {t('timeRegistration.timetrackingentrylogLoading')}
				</p>}
				{changes.sort((a, b) => b.changedAt.localeCompare(a.changedAt)).map((change, index) =>
					<div key={change.id}>
						<div className="font-bold">{dateToDateTimeString(change.changedAt)} {t('common.by')} {change.changedBy?.name} </div>
						{change.changes.map((prop, i) =>
							<div key={i}>
								<div>{t('timeRegistration.log.' + prop.property)} {t('common.from')} {prop.entry1Value} {t('common.to')} <mark>{prop.entry2Value}</mark></div>
							</div>,
						)}
					</div>,
				)}
			</div>}
		/>
	);
};

export default TimeTrackingEntryLogModal;