import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	IonCard,
	IonCardContent,
	IonItem,
	IonIcon,
	IonLabel,
	IonSkeletonText,
	IonList,
	IonGrid,
	IonCol,
	IonRow,
	IonInput,
	IonDatetime,
	IonToggle,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
} from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { faFilter as faFilterSolid } from '@fortawesome/pro-solid-svg-icons';
import { isPlannedDateExceeded } from '@ssg/common/Helpers/drivingSlipsHelper';
import { GetMobileV2DrivingSlipsVariables, GetMobileV2DrivingSlips_drivingSlips_changes } from 'GQL';
import { DrivingSlipStatus } from '@ssg/common/GraphQL';
import BasePage from 'Components/Layout/BasePage';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import classNames from 'classnames';
import Button from 'Components/Button';
import UnhandledError from 'Components/UnhandledError';
import { DEFAULT_DRIVING_SLIPS_FILTERS, Debitors, DrivingSlip, setDrivingSlipsFilters, useAppDispatch, useAppSelector } from 'Store';
import { ApolloError } from '@apollo/client';
import { LabelValuePair, SelectFilter } from './SelectFilter';
import { useDrivingSlipsDataPrefetcher } from './useDrivingSlipsDataPrefetcher';
import { isEmpty, orderBy } from 'lodash';
import Routes, { getRouteWithId } from 'Routes';
import NetworkContext from 'NetworkContext';

enum FilterState {
	NotFiltered,
	SettingFilters,
	Filtered,
}

const defaultFilters: GetMobileV2DrivingSlipsVariables = DEFAULT_DRIVING_SLIPS_FILTERS;

export const getDriverWhoCompleted = (changes: GetMobileV2DrivingSlips_drivingSlips_changes[]): string => {
	const statusCompleteChanges = changes.filter(c => c.after.status !== null && c.after.status === DrivingSlipStatus.COMPLETED);
	const changesCount = statusCompleteChanges.length;
	if (changesCount > 0) {
		return statusCompleteChanges[changesCount - 1].user.name;
	}

	return '';
};

const DrivingSlipsOverview: React.FC = (): React.ReactElement => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { connected } = React.useContext(NetworkContext);

	const prevFilterState = React.useRef<FilterState>(FilterState.NotFiltered);
	const [filterState, setFilterState] = React.useState<FilterState>(prevFilterState.current);

	const { drivingSlipsFilters } = useAppSelector(state => state.localData);

	const setFilters = (partial: Partial<GetMobileV2DrivingSlipsVariables>) => {
		dispatch(setDrivingSlipsFilters(partial));
	};

	// Use a temp copy of the filters to prevent re-querying of the data every time we change a filter - only query once the user submits.
	const [tempFilters, setTempFilters] = React.useState<GetMobileV2DrivingSlipsVariables>(drivingSlipsFilters ?? { ...defaultFilters });

	const [debitorSearchText, setDebitorSearchText] = React.useState<string>();

	const [drivingSlips, setDrivingSlips] = React.useState<DrivingSlip[]>([]);

	const {
		caseAdminData: { data: adminData },
		debitors: { data: debitorsData },
		drivingSlips: { data: drivingSlipsData, error: drivingSlipsError, loading: drivingSlipsLoading, fetchMore: drivingSlipsFetchMore, refetch: drivingSlipsRefetch },
	} = useAppSelector(state => state.apiData);

	const [visibleDebitors, setVisibleDebitors] = useState<Debitors>([]);

	const setDebitorSearch = (value: string | undefined) => {
		setDebitorSearchText(value);

		if (isEmpty(value)) {
			setVisibleDebitors([]);
		}

		setVisibleDebitors(debitorsData.filter(debitor => debitor.company.toLowerCase().includes((value ?? '').toLowerCase())));
	};

	React.useEffect(() => {
		const data = drivingSlipsData.filter(d => (drivingSlipsFilters.status !== DrivingSlipStatus.COMPLETED ? d.status !== DrivingSlipStatus.COMPLETED : true));
		setDrivingSlips(orderBy(data, ds => ds.start, 'asc'));
	}, [drivingSlipsData, drivingSlipsFilters.status]);

	useDrivingSlipsDataPrefetcher(drivingSlips);

	const [departments, locations, damageCauses, damageCategories, drivingSlipCategories] = React.useMemo(() => {
		if (typeof adminData === 'undefined') {
			return [[], [], [], [], []];
		}

		const departments: LabelValuePair[] = adminData.departments
			.filter((d, i, a) => a.findIndex(ad => ad.id === d.id) === i)
			.map(d => ({
				value: d.id,
				label: `${d.name} (${d.departmentNumber})`,
			}))
			.sort((a, b) => a.label.localeCompare(b.label));

		const locations: LabelValuePair[] = adminData.locations
			.filter((l, i, a) => a.findIndex(al => al.id === l.id) === i)
			.map(l => ({ value: l.id, label: l.name }))
			.sort((a, b) => a.label.localeCompare(b.label));

		const damageCauses: LabelValuePair[] = adminData.damageCauses
			.reduce(
				(allCauses, dc) => {
					let cause = allCauses.find(ac => ac.label === dc.name);
					if (typeof cause === 'undefined') {
						const index = allCauses.push({ label: dc.name, value: '' }) - 1;
						cause = allCauses[index];
					}

					cause.value += dc.id;
					return allCauses;
				},
				[] as Array<{ label: string; value: string }>,
			)
			.sort((a, b) => a.label.localeCompare(b.label));

		const damageCategories: LabelValuePair[] = adminData.damageCategories.map(dc => ({ value: dc.id, label: dc.name })).sort((a, b) => a.label.localeCompare(b.label));

		const drivingSlipCategories = adminData.drivingSlipCategories;

		return [departments, locations, damageCauses, damageCategories, drivingSlipCategories];
	}, [adminData]);

	const onFilterSearch = () => {
		if (JSON.stringify(tempFilters) === JSON.stringify(drivingSlipsFilters)) {
			prevFilterState.current = FilterState.NotFiltered;
			setFilterState(FilterState.NotFiltered);
			return;
		}

		prevFilterState.current = FilterState.Filtered;
		setFilterState(FilterState.Filtered);
		setFilters({ ...tempFilters }); // This will force the re-query
	};

	const onFilterClear = () => {
		prevFilterState.current = FilterState.NotFiltered;
		setFilterState(FilterState.NotFiltered);

		const defaultFiltersState = { ...defaultFilters };

		setTempFilters(defaultFiltersState);
		setFilters(defaultFiltersState);
	};

	const onDebitorChange = (debitorId: string, checked: boolean) => {
		let debitors = tempFilters.debitors ?? [];
		if (checked && !debitors.includes(debitorId)) {
			debitors.push(debitorId);
		} else if (!checked) {
			debitors = debitors.filter(id => id !== debitorId);
		}
		setTempFilters({ ...tempFilters, debitors });
	};

	if (drivingSlipsLoading && drivingSlips.length === 0) {
		return (
			<>
				{[...Array(2).keys()].map(index => (
					<IonCard key={index}>
						<IonItem>
							<IonLabel>
								<IonSkeletonText animated className="w-full pt-5" />
							</IonLabel>
						</IonItem>

						<IonCardContent>
							<IonSkeletonText animated className="w-1/4 pt-5" />
							<IonSkeletonText animated className="w-1/2 pt-5" />
							<IonSkeletonText animated className="w-3/4 pt-5" />
							<IonSkeletonText animated className="w-full pt-5" />
						</IonCardContent>
					</IonCard>
				))}
			</>
		);
	}

	if (drivingSlipsError) {
		return (
			<BasePage title={t('drivingSlips.overviewTitle')} refreshFunc={drivingSlipsRefetch}>
				<UnhandledError apolloError={drivingSlipsError as ApolloError} />
			</BasePage>
		);
	}

	return (
		<BasePage title={t('drivingSlips.overviewTitle')} refreshFunc={drivingSlipsRefetch}>
			<div className="flex w-full justify-between px-5">
				<div className="w-18" />
				{connected && !drivingSlipsLoading && (
					<p className="text-xs text-blue flex-grow-default text-center">
						<FontAwesomeIcon icon={faArrowDown} className="mr-1" />
						{t('common.pullDown')}
						<FontAwesomeIcon icon={faArrowDown} className="ml-1" />
					</p>
				)}
				<div
					className="w-18"
					onClick={() => {
						switch (filterState) {
							case FilterState.NotFiltered:
								setFilterState(FilterState.SettingFilters);
								break;
							case FilterState.Filtered:
								setFilterState(FilterState.SettingFilters);
								break;
							case FilterState.SettingFilters:
								setFilterState(prevFilterState.current);
								break;
							default:
								break;
						}
					}}
				>
					<FontAwesomeIcon icon={filterState === FilterState.SettingFilters || filterState === FilterState.Filtered ? faFilterSolid : faFilter} size="lg" className="text-blue mr-1" />
					<span className={classNames('text-blue font-semibold')}>{t('common.filter')}</span>
				</div>
			</div>

			{filterState === FilterState.SettingFilters && (
				<div className="flex flex-col">
					{/* Own | all cases */}
					<IonItem className="mt-3">
						<IonLabel>Vis kun egne</IonLabel>
						<IonToggle
							checked={tempFilters?.personalOnly ?? drivingSlipsFilters?.personalOnly ?? defaultFilters.personalOnly ?? false}
							onIonChange={() =>
								setTempFilters(current => ({
									...current,
									personalOnly: !tempFilters.personalOnly,
								}))
							}
							className="p-0"
							slot="end"
						/>
					</IonItem>

					{/* Status */}
					<SelectFilter
						label={t('common.status')}
						value={tempFilters.status}
						data={[
							{
								label: t('drivingSlips.filters.allDrivingSlips'),
								value: DrivingSlipStatus._NULL_,
							},
							{
								label: t('drivingSlips.status.UNPLANNED'),
								value: DrivingSlipStatus.UNPLANNED,
							},
							{
								label: t('drivingSlips.status.PLANNED'),
								value: DrivingSlipStatus.PLANNED,
							},
							{
								label: t('drivingSlips.status.STARTED'),
								value: DrivingSlipStatus.STARTED,
							},
							{
								label: t('drivingSlips.status.ON_HOLD'),
								value: DrivingSlipStatus.ON_HOLD,
							},
							{
								label: t('drivingSlips.status.COMPLETED'),
								value: DrivingSlipStatus.COMPLETED,
							},
						]}
						onChange={e => setTempFilters({ ...tempFilters, status: e })}
					/>

					{/* Damage Category */}
					<SelectFilter
						label={t('common.damageCategory')}
						value={tempFilters.damageCategories}
						data={damageCategories}
						onChange={e =>
							setTempFilters({
								...tempFilters,
								damageCategories: e,
							})
						}
						multiple={true}
					/>

					{/* Damage Cause */}
					<SelectFilter
						label={t('common.damageCause')}
						value={tempFilters.damageCauses}
						data={damageCauses}
						// onChange={e => {
						// 	const values = (e as string[]) ?? [];
						// 	const damageCauses = values.flatMap(v => v.split(',').filter(v => v !== '')) ?? [];
						// 	setTempFilters({ ...tempFilters, damageCauses });
						// }}
						onChange={e => setTempFilters({ ...tempFilters, damageCauses: e })}
						multiple={true}
					/>

					{/* Department */}
					<SelectFilter
						label={t('common.department')}
						value={tempFilters.departments}
						data={departments}
						onChange={e => setTempFilters({ ...tempFilters, departments: e })}
						multiple={true}
					/>

					{/* Location */}
					<SelectFilter label={t('common.location')} value={tempFilters.locations} data={locations} onChange={e => setTempFilters({ ...tempFilters, locations: e })} multiple={true} />

					{/* Debitors */}
					<label className="text-blue mt-2 px-5 font-semibold">{t('common.debitor')}</label>
					<p className="pl-5 text-blue">Debitor filter er midlertidigt slået fra</p>
					{/* <IonSearchbar value={debitorSearchText} onIonChange={e => setDebitorSearch(e.detail.value)} className="pl-5 pr-2" placeholder={t('common.search')} />
					<IonList style={{ maxHeight: '200px' }} className="overflow-auto">
						{debitorsLoading && (
							<IonItem>
								<IonLabel>
									<IonSkeletonText animated className="w-full pt-5" />
								</IonLabel>
							</IonItem>
						)}
						{!debitorsLoading &&
							visibleDebitors.map(debitor => (
								<IonItem key={debitor.debitorId}>
									<IonCheckbox slot="start" onIonChange={e => onDebitorChange(debitor.debitorId, e.detail.checked)} checked={tempFilters.debitors?.includes(debitor.debitorId)} />
									<IonLabel>{debitor.company}</IonLabel>
								</IonItem>
							))}
					</IonList> */}

					{/* Postal code */}
					<label className="text-blue mt-2 px-5 font-semibold">{t('common.postalcode')}</label>
					<IonInput
						value={tempFilters.postalCode}
						onIonChange={e =>
							setTempFilters({
								...tempFilters,
								postalCode: e.detail.value,
							})
						}
						placeholder={t('common.postalcode')}
						className="pl-8 pr-2"
					/>

					{/* Case No. */}
					<label className="text-blue mt-2 px-5 font-semibold">{t('case.caseNo')}</label>
					<IonInput
						value={tempFilters.caseERPReferenceNo}
						onIonChange={e =>
							setTempFilters({
								...tempFilters,
								caseERPReferenceNo: e.detail.value?.toUpperCase(),
							})
						}
						placeholder={t('case.caseNo')}
						className="pl-8 pr-2"
					/>

					<label className="text-blue mt-2 px-5 font-semibold">
						{t('common.from')} {t('common.date').toLocaleLowerCase()}
					</label>
					<IonDatetime
						presentation="date"
						size="cover"
						value={tempFilters.minStartDate}
						onIonChange={e =>
							setTempFilters({
								...tempFilters,
								minStartDate: (e.detail.value as string)?.split('T')[0],
							})
						}
					/>

					{/* Max date */}
					<label className="text-blue mt-2 px-5 font-semibold">
						{t('common.to')} {t('common.date').toLocaleLowerCase()}
					</label>
					<IonDatetime
						presentation="date"
						size="cover"
						value={tempFilters.maxStartDate}
						onIonChange={e =>
							setTempFilters({
								...tempFilters,
								maxStartDate: (e.detail.value as string)?.split('T')[0],
							})
						}
					/>

					<Button className="px-4" onClick={onFilterSearch}>
						{t('common.search')}
					</Button>
					<Button className="px-4" color="light" onClick={onFilterClear}>
						{t('common.clear')}
					</Button>
				</div>
			)}

			{filterState !== FilterState.SettingFilters && drivingSlips.length > 0 && (
				<>
					<IonList>
						{drivingSlips.map((drivingSlip, index) => (
							<IonCard key={index} routerDirection="forward" routerLink={getRouteWithId(Routes.DrivingSlipInfo, drivingSlip.id)}>
								<IonCardContent className="p-3">
									<IonGrid>
										<IonRow className="border-b-1 text-xs">
											<IonCol>
												{isPlannedDateExceeded(drivingSlip.start) && (
													<div className="text-red mb-1 mr-3 flex flex-row items-center font-bold">
														<IonIcon icon={alertCircleOutline} slot="start" size="lg" />
														<p className="ml-1">{t('common.overdue')}</p>
													</div>
												)}

												{drivingSlip.urgent && (
													<div className="text-orange mb-1 mr-3 flex flex-row items-center font-bold">
														<IonIcon icon={alertCircleOutline} slot="start" size="lg" />
														<p className="ml-1">{t('common.urgent')}</p>
													</div>
												)}

												{drivingSlip.case.skafor && (
													<div className="text-purple mb-1 mr-3 flex flex-row items-center font-bold">
														<IonIcon icon={alertCircleOutline} slot="start" size="lg" />
														<p className="ml-1">{t('case.SKAFOR')}</p>
													</div>
												)}
											</IonCol>
											<IonCol>
												<p className="text-right text-xs text-gray-600">{dateToDateTimeString(drivingSlip.createdAt)}</p>
											</IonCol>
										</IonRow>
										<IonRow className="mt-1">
											<IonCol>
												<p className="text-blue font-semibold">{t('planner.preferredTo')}</p>
												<p>{dateToDateTimeString(drivingSlip.start)}</p>
											</IonCol>
											<IonCol>
												<p className="text-blue font-semibold">{t('planner.projectNo')}</p>
												<p>{drivingSlip.case.erpNo}</p>
											</IonCol>
										</IonRow>

										<IonRow>
											<IonCol>
												<p className="text-blue font-semibold">{t('common.damageCategory')}</p>
												<p>{drivingSlip.case.damage.category.name}</p>
												<p className="text-blue font-semibold">{t('common.damageCause')}</p>
												<p>{drivingSlip.case.damage.cause.name}</p>
											</IonCol>

											<IonCol>
												<p className="text-blue font-semibold">{t('case.ssgCaseManager')}</p>
												<p>{drivingSlip.case.caseManager?.name ?? '-'}</p>
												<p className="text-blue font-semibold">{t('case.ssgProjectManager')}</p>
												<p>{drivingSlip.case.projectManager?.name ?? '-'}</p>
											</IonCol>
										</IonRow>

										<IonRow>
											<IonCol>
												<p className="text-blue font-semibold">{t('case.injuredParty')}</p>
												<p>{drivingSlip.case.damage.contact.name}</p>
												<p>
													{drivingSlip.case.damage.contact.address.road} {drivingSlip.case.damage.contact.address.houseNumber}{' '}
													{drivingSlip.case.damage.contact.address.floor ?? ''}
												</p>
												<p>{`${drivingSlip.case.damage.contact.address.postalCode} ${drivingSlip.case.damage.contact.address.city}`}</p>
												<p>
													<a
														href={`tel:${drivingSlip.case.damage.contact.phone}`}
														className={classNames('hover:underline', {
															hidden: !drivingSlip.case.damage.contact.phone,
														})}
													>
														{t('common.tel')} {formatPhoneNumberIntl(drivingSlip.case.damage.contact.phone)}
													</a>
												</p>
												<p>
													<a
														href={`mailto:${drivingSlip.case.damage.contact.email}`}
														className={classNames('hover:underline', {
															hidden: !drivingSlip.case.damage.contact.email,
														})}
													>
														{t('common.mail')} {drivingSlip.case.damage.contact.email}
													</a>
												</p>
											</IonCol>
											<IonCol>
												<p className="text-blue font-semibold">{t('common.debitor')}</p>
												<p>{drivingSlip.case.debitor.company}</p>
												<p>{drivingSlip.case.debitor.address?.addressLine}</p>
												<p>{`${drivingSlip.case.debitor.address?.postalCode} ${drivingSlip.case.debitor.address?.city}`}</p>
												<p>
													<a
														href={`tel:${drivingSlip.case.debitor.phone}`}
														className={classNames('hover:underline', {
															hidden: !drivingSlip.case.debitor.phone,
														})}
													>
														{t('common.tel')} {formatPhoneNumberIntl(drivingSlip.case.debitor.phone ?? '').includes('+45') ? formatPhoneNumberIntl(drivingSlip.case.debitor.phone ?? '') : `+45 ${drivingSlip.case.debitor.phone}`}
													</a>
												</p>
												<p>
													<a
														href={`mailto:${drivingSlip.case.debitor.email}`}
														className={classNames('hover:underline', {
															hidden: !drivingSlip.case.debitor.email,
														})}
													>
														{t('common.mail')} {drivingSlip.case.debitor.email}
													</a>
												</p>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<p className="text-blue font-semibold">{t('common.location')}</p>
												<p>{drivingSlip.case.ssgLocation.name}</p>
											</IonCol>

											<IonCol>
												<p className="text-blue font-semibold">{t('common.category')}</p>
												<p>{drivingSlipCategories.find(c => c.code === drivingSlip.category)?.name ?? '-'}</p>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<p className="text-blue font-semibold">{t('common.status')}</p>
												<p>{t(`drivingSlips.status.${drivingSlip.status}`)}</p>
												<p>{getDriverWhoCompleted(drivingSlip.changes)}</p>
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonCardContent>
							</IonCard>
						))}
					</IonList>
					<IonInfiniteScroll
						onIonInfinite={async (ev: CustomEvent<void>) => {
							const event = ev as CustomEvent<void>;
							const target = event?.target;

							await drivingSlipsFetchMore({
								offset: drivingSlips.length,
							});

							if (target) {
								(target as any).complete();
							}
						}}
					>
						<IonInfiniteScrollContent></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</>
			)}

			{filterState !== FilterState.SettingFilters && drivingSlips.length === 0 && (
				<div className="text-blue mt-10 text-center">
					<p>{t('drivingSlips.noDrivingSlipsFound')}</p>
				</div>
			)}
		</BasePage>
	);
};

export default DrivingSlipsOverview;
